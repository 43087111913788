import React from "react";

import Layout from "../other/layout";
import SEO from "../other/seo";

const Index = () => {
  return (
    <Layout>
      <SEO title="Website Accessibility Policy" />
      <div class="row website-accessibility-policy-wrapper">
        <h1 class="categoryTitle">Website Accessibility Policy</h1>
        <div class="policy-wrapper">
          <p>
            Deluxe is committed to providing individuals with disabilities
            access to goods, services, and privileges offered on the website{" "}
            <a href="https://www.deluxe.com" target="_blank">
              www.deluxe.com (opens in new tab)
            </a>{" "}
            and is partaking in ongoing efforts to improve the accessibility of{" "}
            <a href="https://www.deluxe.com" target="_blank">
              www.deluxe.com (opens in new tab)
            </a>
            . If you have specific questions or concerns about this site's
            accessibility or need assistance with using this site, please
            contact us. Please call <a href="tel:18004906327">800.490.6327</a>.
            Or email{" "}
            <a href="mailto:accessibility@deluxe.com">
              accessibility@deluxe.com
            </a>
            . When contacting us, please specify the particular web page about
            which you have a question, want to submit a comment, or require
            assistance.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Index;
